// @flow
import * as React from 'react';
import './AdminTelemetry.css';
import PageLayout from '../../../../Components/PageLayout/PageLayout';
import {default as bgImage} from './Images/Telemetry.jpg';
import TelemetryService from '../../../../Services/TelemetryService';
import AdminPageContentWrap from '../../../../Components/AdminPageContentWrap/AdminPageContentWrap';
import Utils from "../../../../utils";
import AuthStatusProviderService from "../../../../Services/AuthStatusProviderService";
import PortfolioLib from "goyette-portfolio-lib";
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

let telemetryService = new TelemetryService();

type Props = { /* ... */ };

type State = {
    currentLevelId: ?string,
    levelIdOptions: Array<string>,

    currentPlayerId: ?string,
    playerIdOptions: Array<PortfolioLib.KeyValuePairModel>,

    applicationMajorVersion : ?string,
    applicationMinorVersion : ?string,
    applicationReleaseVersion : ?string,
    applicationVersionExactMatch: ?boolean,

    playerQuitLevel: ?boolean;
    hasPreviouslyCompletedLevel: ?boolean;
    playerLevelRatingMin: ?number;
    playerLevelRatingMax: ?number;

    groupByPlayer: ?boolean;
    includeAdminEntries: ?boolean;


    telemetryTabularData: Array<PortfolioLib.TelemetryDataModel>;
    secretFoundTabularData: Array<PortfolioLib.SecretFoundSearchResultModel>;
    cutscenePlayedTabularData: Array<PortfolioLib.CutscenePlayedSearchResultModel>;

    levelCompletionAnalysisData: Array<PortfolioLib.TelemetryLevelCompletionAnalysisDataModel>;
    playerRetentionAnalysisData: Array<PortfolioLib.TelemetryPlayerRetentionAnalysisDataModel>;
    shouldShowRawPlayerRetentionValues: ?boolean;

    isInitialized: boolean,
    authStatus: PortfolioLib.AuthStatusModel,
    authStatusProviderSubscriptionKey: string
};
class AdminTelemetry extends React.Component<Props, State> {

    //props: {};
    //static defaultProps = {    };
    //state: {}



    doInitialization() {
        this.setState({applicationMajorVersion: "0"});
        this.setState({applicationMinorVersion: "10"});
        this.setState({applicationReleaseVersion: "0"});

        if (this.isDoingInit) {
            console.log("Already initializing. Queued for reinit.");
            this.shouldReinit = true;
            return;
        }

        if (this.state.authStatus.isAdmin) {
            this.setState({isInitialized: false});

            // Continue loading.
            Promise.all([
               // this.fetchPlayerIds(),
                this.fetchLevelIds(),
                this.fetchPlayerNames()
                ]
            ).then(() => {

                this.setState({isInitialized: true});

                this.isDoingInit = false;
                if (this.shouldReinit) {
                    this.shouldReinit = false;
                    this.doInitialization();
                }
            })
            .catch((err) => {
                Utils.notifyException(err, "Error Initializing Telemetry page");
                this.isDoingInit = false;
                if (this.shouldReinit) {
                    this.shouldReinit = false;
                    this.doInitialization();
                }
            });
        } else {
            // Initialization is done, but will result in no access granted.
            console.log("Not an admin... Sorry");
            this.setState({isInitialized: true});
        }

    }

    isDoingInit: boolean;
    shouldReinit: boolean;

    constructor(props: $PropertyType<AdminTelemetry, 'props'>) {
        super(props);


        this.state = {
            currentLevelId: "",
            levelIdOptions: [],

            currentPlayerId: "",
            playerIdOptions: [],

            applicationMajorVersion: "",
            applicationMinorVersion: "",
            applicationReleaseVersion: "",
            applicationVersionExactMatch: false,

            playerQuitLevel: null,
            hasPreviouslyCompletedLevel: null,
            playerLevelRatingMin: 0,
            playerLevelRatingMax: 0,

            groupByPlayer: false,
            includeAdminEntries: false,

            telemetryTabularData: [],
            secretFoundTabularData: [],
            cutscenePlayedTabularData: [],

            levelCompletionAnalysisData:  [],
            playerRetentionAnalysisData: [],
            shouldShowRawPlayerRetentionValues: false,

            isInitialized: false,
            authStatus: AuthStatusProviderService.instance.authStatusModel,
            authStatusProviderSubscriptionKey: ""
        };
    }



    componentWillUnmount() {
        AuthStatusProviderService.instance.unsubscribe(this.state.authStatusProviderSubscriptionKey);
    }

    componentDidMount() {
        this.setState({authStatusProviderSubscriptionKey: AuthStatusProviderService.instance.subscribe((newAuthStatus) => this.updateAuthStatus(newAuthStatus))});

      //  this.doInitialization();
    }

    updateAuthStatus(newAuthStatus: PortfolioLib.AuthStatusModel) {
        let action = this.state.authStatus.isAdmin === newAuthStatus.isAdmin
            ? () => {} : () => this.doInitialization();
        this.setState({authStatus: newAuthStatus}, action);
    }


    render() : React.Node {
        return (
            <PageLayout caption="Admin Telemetry"  showBannerImage={true} backgroundImage={bgImage}>
                <AdminPageContentWrap>
                   {/*Dropdowns:*/}
                    {/*Version - Either a specific version, or anything newer than a certain amount.*/}
                    {/*Level - Just do the Level ID. Either All, or a specific one.*/}
                    {/*Player Name + ID - Either all, or a specific person.*/}

                    <div className="AdminTelemetry-filter-row">
                        <span className="AdminTelemetry-filter-option-label">Level: </span>
                        <select className="AdminTelemetry-filter-option-dropdown"
                                value={this.state.currentLevelId}
                                onChange={(e) => this.handleLevelIdChange(e)}>
                            <option key="" value="">All</option>
                            {this.state.levelIdOptions.map((fv, i) => {
                                return <option key={fv} value={fv}>
                                    {fv}
                                </option>
                            })}
                        </select>
                    </div>

                    <div className="AdminTelemetry-filter-row">
                        <span className="AdminTelemetry-filter-option-label">Player: </span>
                        <select className="AdminTelemetry-filter-option-dropdown"
                                value={this.state.currentPlayerId}
                                onChange={(e) => this.handlePlayerIdChange(e)}>
                            <option key="" value="">All</option>
                            {this.state.playerIdOptions.map((fv, i) => {
                                return <option key={fv.key} value={fv.key}>
                                    {fv.value}
                                </option>
                            })}
                        </select>
                    </div>

                    <div className="AdminTelemetry-filter-row">
                        <span className="AdminTelemetry-filter-option-label">Version: </span>

                        <input
                            type="text"
                            className="AdminTelemetry-version-input"
                            style={{flex: "1"}}
                            value={this.state.applicationMajorVersion}
                            onChange={(e) => this.handleApplicationMajorVersionValueChange(e)}/>
                        <input
                            type="text"
                            className="AdminTelemetry-version-input"
                            style={{flex: "1"}}
                            value={this.state.applicationMinorVersion}
                            onChange={(e) => this.handleApplicationMinorVersionValueChange(e)}/>
                        <input
                            type="text"
                            className="AdminTelemetry-version-input"
                            style={{flex: "1"}}
                            value={this.state.applicationReleaseVersion}
                            onChange={(e) => this.handleApplicationReleaseVersionValueChange(e)}/>

                        <span >Exact: </span>
                        <input
                            type="checkbox"
                            checked={this.state.applicationVersionExactMatch}
                            onChange={(e) => this.handleApplicationVersionIsExactInputChange(e)}/>

                    </div>
                    <div className="AdminTelemetry-filter-row">
                        <span className="AdminTelemetry-filter-option-label">Player Quit? </span>
                        <select className="AdminTelemetry-filter-option-dropdown"
                                value={this.state.playerQuitLevel}
                                onChange={(e) => this.handlePlayerQuitLevelChange(e)}>
                            <option key="" value="">All</option>
                            <option key="true" value="true">Yes</option>
                            <option key="false" value="false">No</option>
                        </select>
                    </div>
                    <div className="AdminTelemetry-filter-row">
                        <span className="AdminTelemetry-filter-option-label">Replay? </span>
                        <select className="AdminTelemetry-filter-option-dropdown"
                                value={this.state.hasPreviouslyCompletedLevel}
                                onChange={(e) => this.handleHasPreviouslyCompletedLevelChange(e)}>
                            <option key="" value="">All</option>
                            <option key="true" value="true">Yes</option>
                            <option key="false" value="false">No</option>
                        </select>
                    </div>
                    <div className="AdminTelemetry-filter-row">
                        <span className="AdminTelemetry-filter-option-label">Rating (Min/Max): </span>

                        <input
                            type="text"
                            className="AdminTelemetry-version-input"
                            style={{flex: "1"}}
                            value={this.state.playerLevelRatingMin}
                            onChange={(e) => this.handlePlayerLevelRatingMinValueChange(e)}/>
                        <input
                            type="text"
                            className="AdminTelemetry-version-input"
                            style={{flex: "1"}}
                            value={this.state.playerLevelRatingMax}
                            onChange={(e) => this.handlePlayerLevelRatingMaxValueChange(e)}/>
                    </div>
                    <div className="AdminTelemetry-filter-row">
                        <span className="AdminTelemetry-filter-option-label">Group by Player: </span>

                        <input
                            type="checkbox"
                            checked={this.state.groupByPlayer}
                            onChange={(e) => this.handleGroupByPlayerInputChange(e)}/>

                    </div>
                    <div className="AdminTelemetry-filter-row">
                        <span className="AdminTelemetry-filter-option-label">Include Admin Entries: </span>

                        <input
                            type="checkbox"
                            checked={this.state.includeAdminEntries}
                            onChange={(e) => this.handleIncludeAdminEntriesInputChange(e)}/>

                    </div>
                    <div className="spacer-row" />


                    {/* Search Results */}

                    {/*<Tabs defaultIndex={3}>*/}
                    <Tabs>
                        <TabList>
                            <Tab>Raw Search Results</Tab>
                            <Tab>Secrets Found</Tab>
                            <Tab>Cutscene Stats</Tab>
                            <Tab>Level Completion</Tab>
                            <Tab>Player Retention</Tab>
                        </TabList>



                        <TabPanel>
                            <div>
                                <button className="AdminTelemetry-submit-button button" onClick={(e) => this.submitSearchRequest(e)}>
                                    <span>Search</span>
                                </button>
                                <button className="AdminTelemetry-submit-button button" onClick={(e) => this.submitDownloadRequest(e)}>
                                    <span>Download</span>
                                </button>
                                <ReactTable
                                    data={this.state.telemetryTabularData}
                                    defaultPageSize={10}
                                    showPaginationTop={true}
                                    minWidth={1200}
                                    className={"tabular-data"}
                                    columns={[
                                        {
                                            //Header: "Player",
                                            columns: [
                                                {
                                                    Header: "Player",
                                                    width: 225,
                                                    accessor: "_playerName"
                                                },
                                            ]
                                        },
                                        {
                                            columns: [
                                                {
                                                    Header: "Date",
                                                    id: "date",
                                                    accessor: d => new Date(d._date).toLocaleString(),
                                                    width: 175,
                                                }
                                            ]
                                        },
                                        {
                                            columns: [
                                                {
                                                    Header: "Level",
                                                    id: "levelId",
                                                    accessor: d => this.AbbreviateLevelID(d._levelId),
                                                    width: 125,
                                                }
                                            ]
                                        },
                                        //{
                                        //    columns: [
                                        //        {
                                        //            Header: "Description",
                                        //            accessor: "_playerLevelRatingDescription",
                                        //            width: 500,
                                        //            Cell: ({ row }) => <div style={{whiteSpace: "normal"}}>{row._playerLevelRatingDescription}</div>
                                        //        }
                                        //    ]
                                        //},
                                        //{
                                        //    columns: [
                                        //        {
                                        //            Header: "Rating",
                                        //            accessor: "_playerLevelRating"
                                        //        }
                                        //    ]
                                        //},

                                        {
                                            columns: [
                                                {
                                                    Header: "Time",
                                                    accessor: "_timeSpentInLevel"
                                                }
                                            ]
                                        },
                                        {
                                            columns: [
                                                {
                                                    Header: "Deaths",
                                                    accessor: "_deathCount"
                                                }
                                            ]
                                        },
                                        {
                                            columns: [
                                                {
                                                    Header: "Restarts",
                                                    id: "restarts",
                                                    accessor: d => d._restartCount
                                                }
                                            ]
                                        },
                                        {
                                            columns: [
                                                {
                                                    Header: "Secrets",
                                                    id: "totalSecrets",
                                                    accessor: d => d._totalSecretsFound
                                                }
                                            ]
                                        },
                                        {
                                            columns: [
                                                {
                                                    Header: "Quit?",
                                                    id: "quit",
                                                    accessor: d => d._playerQuitLevel + ""
                                                }
                                            ]
                                        },
                                        {
                                            columns: [
                                                {
                                                    Header: "Time Trial?",
                                                    id: "timeTrial",
                                                    accessor: d => d._isInTimeTrialMode
                                                }
                                            ]
                                        },
                                        {
                                            columns: [
                                                {
                                                    Header: "Replay?",
                                                    id: "replay",
                                                    accessor: d => d._hasPreviouslyCompletedLevel + ""
                                                }
                                            ]
                                        },
                                        {
                                            columns: [
                                                {
                                                    Header: "Version",
                                                    id: "version",
                                                    accessor:  d => d._applicationMajorVersion + "." + d._applicationMinorVersion + "." + d._applicationReleaseVersion
                                                }
                                            ]
                                        }

                                    ]}
                                    //className="-striped -highlight"
                                />
                            </div>

                        </TabPanel>




                        {/* Secrets Results */}
                        <TabPanel>
                            <button className="AdminTelemetry-submit-button button" onClick={(e) => this.submitSecretFoundRequest(e)}>
                                <span>Search</span>
                            </button>

                            <div>
                                <ReactTable
                                    data={this.state.secretFoundTabularData}
                                    defaultPageSize={10}
                                    showPaginationTop={true}
                                    minWidth={1200}
                                    className={"tabular-data"}
                                    columns={[
                                        {
                                            columns: [
                                                {
                                                    Header: "ID",
                                                    id: "secretId",
                                                    width: 100,
                                                    accessor:  d => d._secretId
                                                },
                                            ]
                                        },
                                        {
                                            columns: [
                                                {
                                                    Header: "Name",
                                                    id: "secretName",
                                                    width: 700,
                                                    accessor:  d => d._secretName
                                                },
                                            ]
                                        },
                                        {
                                            columns: [
                                                {
                                                    Header: "Times Found",
                                                    id: "numberOfTimesFound",
                                                    width: 200,
                                                    accessor:  d => d._numberOfTimesFound
                                                },
                                            ]
                                        },

                                    ]}
                                    //className="-striped -highlight"
                                />
                            </div>
                        </TabPanel>


                        {/* Cutscene Stats */}
                        <TabPanel>
                            <button className="AdminTelemetry-submit-button button"
                                    onClick={(e) => this.submitCutscenePlayedRequest(e)}>
                                <span>Search</span>
                            </button>
                            <span>
                                <em>(Remember, "M-Skip %" is not "M-Skip / Played". It's "M-Skip / (M-Skip + !Skip)".)</em>
                            </span>
                            <div>
                                <ReactTable
                                    data={this.state.cutscenePlayedTabularData}
                                    defaultPageSize={10}
                                    showPaginationTop={true}
                                    minWidth={1200}
                                    className={"tabular-data"}
                                    columns={[
                                        {
                                            columns: [
                                                {
                                                    Header: "ID",
                                                    id: "cutsceneId",
                                                    width: 80,
                                                    accessor: d => d._cutsceneId
                                                },
                                            ]
                                        },
                                        {
                                            columns: [
                                                {
                                                    Header: "Name",
                                                    id: "cutsceneName",
                                                    width: 350,
                                                    accessor: d => d._cutsceneName
                                                },
                                            ]
                                        },
                                        {
                                            columns: [
                                                {
                                                    Header: "Played",
                                                    id: "numberOfTimesPlayed",
                                                    width: 80,
                                                    accessor: d => d._numberOfTimesPlayed
                                                },
                                            ]
                                        },
                                        {
                                            columns: [
                                                {
                                                    Header: "A-Skip",
                                                    id: "numberOfTimesAutoSkipped",
                                                    width: 80,
                                                    accessor: d => d._numberOfTimesAutoSkipped
                                                },
                                            ]
                                        },
                                        {
                                            columns: [
                                                {
                                                    Header: "M-Skip",
                                                    id: "numberOfTimesManuallySkipped",
                                                    width: 80,
                                                    accessor: d => d._numberOfTimesManuallySkipped
                                                },
                                            ]
                                        },
                                        {
                                            columns: [
                                                {
                                                    Header: "M-Skip %",
                                                    id: "manualSkipRate",
                                                    width: 100,
                                                    accessor: d => Number(d._manualSkipRate * 100).toFixed(0),
                                                    sortMethod: (a, b) => Number(a) - Number(b)
                                                },
                                            ]
                                        },
                                        {
                                            columns: [
                                                {
                                                    Header: "M-Skip μT",
                                                    id: "averageManualSkipTimeRaw",
                                                    width: 100,
                                                    accessor: d => Number(d._averageManualSkipTimeRaw).toFixed(2),
                                                    sortMethod: (a, b) => Number(a) - Number(b)
                                                },
                                            ]
                                        },
                                        {
                                            columns: [
                                                {
                                                    Header: "M-Skip μT%",
                                                    id: "averageManualSkipTimePercent",
                                                    width: 120,
                                                    accessor: d => Number(d._averageManualSkipTimePercent).toFixed(2),
                                                    sortMethod: (a, b) => Number(a) - Number(b)
                                                },
                                            ]
                                        },
                                    ]}
                                    //className="-striped -highlight"
                                />
                            </div>
                        </TabPanel>


                        <TabPanel>
                            <button className="AdminTelemetry-submit-button button" onClick={(e) => this.submitLevelCompletionAnalysisRequest(e)}>
                                <span>Search</span>
                            </button>

                            <div>
                                <ReactTable
                                    data={this.state.levelCompletionAnalysisData}
                                    defaultPageSize={10}
                                    showPaginationTop={true}
                                    minWidth={1200}
                                    className={"tabular-data"}
                                    columns={[
                                        {
                                            columns: [
                                                {
                                                    Header: "Level ID",
                                                    id: "fullLevelId",
                                                    width: 300,
                                                    accessor:  d => d._fullLevelId,
                                                    sortMethod: (a, b) => this.getLevelOrder(a)-this.getLevelOrder(b)
                                                },
                                            ]
                                        },
                                        {
                                            columns: [
                                                {
                                                    Header: "Average Deaths",
                                                    id: "averageDeaths",
                                                    width: 200,
                                                    accessor:  d => Number(d._averageNumberOfDeaths).toFixed(2),
                                                    sortMethod: (a, b) => Number(a)-Number(b)
                                                },
                                            ]
                                        },
                                        {
                                            columns: [
                                                {
                                                    Header: "Average Time",
                                                    id: "averageTimeSpent",
                                                    width: 200,
                                                    accessor:  d => Number(d._averageTimeSpent).toFixed(2),
                                                    sortMethod: (a, b) => Number(a)-Number(b)
                                                },
                                            ]
                                        },

                                    ]}
                                    //className="-striped -highlight"
                                />
                            </div>
                        </TabPanel>

                        <TabPanel>
                            <button className="AdminTelemetry-submit-button button" onClick={(e) => this.submitPlayerRetentionAnalysisRequest(e)}>
                                <span>Search</span>
                            </button>

                            <span >Display Raw Values: </span>
                            <input
                                type="checkbox"
                                checked={this.state.shouldShowRawPlayerRetentionValues}
                                onChange={(e) => this.handleShouldShowRawPlayerRetentionValuesInputChange(e)}/>



                            <div className="spacer-row" />
                            <div>
                                <ReactTable
                                    data={this.state.playerRetentionAnalysisData}
                                    defaultPageSize={10}
                                    showPaginationTop={true}
                                    minWidth={1200}
                                    className={"tabular-data"}
                                    columns={[
                                        {
                                            columns: [
                                                {
                                                    Header: "Level ID",
                                                    id: "fullLevelId",
                                                    width: 400,
                                                    accessor:  d => d._fullLevelId,
                                                    sortMethod: (a, b) => this.getLevelOrder(a)-this.getLevelOrder(b)
                                                },
                                            ]
                                        },
                                        {
                                            columns: [
                                                {
                                                    Header: "Last Level (Raw)",
                                                    id: "wasPlayersLastLevelCount",
                                                    width: 200,
                                                    accessor:  d => d._wasPlayersLastLevelCount
                                                },
                                            ]
                                        },
                                        {
                                            columns: [
                                                {
                                                    Header: "Last Level (Percent)",
                                                    id: "percentageOfTotal",
                                                    width: 200,
                                                    accessor:  d => (100 * Number(d._percentageOfTotal)).toFixed(2) ,
                                                    sortMethod: (a, b) => Number(a)-Number(b)
                                                },
                                            ]
                                        },
                                        {
                                            columns: [
                                                {
                                                    Header: "Last Level (Percent of Remaining)",
                                                    id: "percentageOfRemaining",////
                                                    width: 200,
                                                    accessor:  d => (100 * Number(d._percentageOfRemaining)).toFixed(1),
                                                    sortMethod: (a, b) => Number(a)-Number(b)
                                                },
                                            ]
                                        }
                                    ]}
                                    //className="-striped -highlight"
                                />
                            </div>

                            <div className="spacer-row" />
                            <h3>Visualization</h3>

                            <table>
                                <thead>
                                    <tr>
                                        <th className="AdminTelemetry-retention-table-cell">Level ID</th>
                                        <th className="AdminTelemetry-retention-table-cell" style={{width: "50px"}}>Stopped Here (%)</th>
                                        <th className="AdminTelemetry-retention-table-cell" style={{width: "50px"}}>Stopped Here (%R)</th>
                                        {this.state.shouldShowRawPlayerRetentionValues &&
                                            <th className="AdminTelemetry-retention-table-cell" style={{width: "50px"}}>Stopped Here (Raw)</th>
                                        }
                                        <th>Raw Data</th>
                                        <th style={{width: "150px"}}>Percent (Normalized)</th>
                                        <th style={{width: "150px"}}>Percent of Reached</th>
                                        <th style={{width: "150px"}}>Reached</th>

                                    </tr>
                                </thead>
                                <tbody>
                                {this.state.playerRetentionAnalysisData
                                    .sort((a, b) => a._order > b._order ? 1 : -1)
                                    .map((record, i) => {
                                        let percentOfTotalStyleVal = {backgroundColor: "red", width: `${100 * record._normalizedPercentageOfTotal}%`};
                                        let percentOfRemainingStyleVal = {backgroundColor: "red", width: `${100 * record._percentageOfRemaining}%`};
                                        let cumulativePercentRemainingStyleVal  = {backgroundColor: "red", width: `${100 * record._cumulativePercentRemaining}%`};
                                        return <tr key={record._fullLevelId}>
                                            <td className="AdminTelemetry-retention-table-cell">
                                                {record._fullLevelId}
                                            </td>
                                            <td className="AdminTelemetry-retention-table-cell">
                                                <div style={{width: "50px"}}>{(100 * record._percentageOfTotal).toFixed(1)}%</div>
                                            </td>
                                            <td className="AdminTelemetry-retention-table-cell" >
                                                <div style={{width: "50px"}} >{(100 * record._percentageOfRemaining).toFixed(1)}%</div>
                                            </td>
                                            {this.state.shouldShowRawPlayerRetentionValues &&
                                            <td className="AdminTelemetry-retention-table-cell">
                                                <div style={{width: "50px"}}>{record._wasPlayersLastLevelCount}</div>
                                            </td>
                                            }
                                            <td>
                                                <button className="AdminTelemetry-small-submit-button button" onClick={(e) => this.submitDownloadRequestForPlayerRetentionData(e, record._fullLevelId)}>
                                                    <span>Download</span>
                                                </button>
                                            </td>
                                            <td style={{width: "150px", marginRight: "10px"}}>
                                                <div style={{backgroundColor: "white", width: "90%"}}>
                                                    <div style={percentOfTotalStyleVal}>&nbsp;</div>
                                                </div>
                                            </td>
                                            <td style={{width: "150px", marginRight: "10px"}}>
                                                <div style={{backgroundColor: "white", width: "90%"}}>
                                                    <div style={percentOfRemainingStyleVal}>&nbsp;</div>
                                                </div>
                                            </td>
                                            <td style={{width: "150px"}}>
                                                <div style={{backgroundColor: "white", width: "90%"}}>
                                                    <div style={cumulativePercentRemainingStyleVal}>&nbsp;</div>
                                                </div>
                                            </td>

                                        </tr>
                                    })}
                                </tbody>
                            </table>


                        </TabPanel>



                    </Tabs>

                    <div className="spacer-row" />
                    <div className="spacer-row" />
                    <div className="spacer-row" />
                    <div className="spacer-row" />

                </AdminPageContentWrap>
            </PageLayout>
        );
    }

    AbbreviateLevelID(levelID: string) {
        return levelID.replace('Introduction', 'I')
            .replace('BasicAssessment', 'BA')
            .replace('AdvancedTraining', 'AT')
            .replace('CombatScenarios', 'CS')
            .replace('CoreResearch', 'CR');
    }

    submitSearchRequest(event: Event) {
        let telemetrySearchModel = this.getSearchModel();

        telemetryService.fetchTelemetryTabularData(telemetrySearchModel)
            .then((values) => {
                //console.log(values);

                this.setState({telemetryTabularData: values});
            })
            .catch((err) => {
                Utils.notifyException(err, "Error fetching tabular data");
            });

    }

    submitDownloadRequest(event: Event) {
        let telemetrySearchModel = this.getSearchModel();

        telemetryService.downloadTelemetryBinaryData(telemetrySearchModel, "telemetryData");
    }

    submitDownloadRequestForPlayerRetentionData(event: Event, fullLevelId: string) {
        let telemetrySearchModel = this.getSearchModel();

        telemetrySearchModel.isPlayerRetentionRequest = true;
        telemetrySearchModel.fullLevelId = fullLevelId;
        telemetrySearchModel.groupByPlayer = false;

        telemetryService.downloadTelemetryBinaryData(telemetrySearchModel, "retentionTelemetry_" + fullLevelId);
    }

    getSearchModel() : PortfolioLib.TelemetrySearchModel {
        // Generate the request model.
        let telemetrySearchModel = PortfolioLib.TelemetrySearchModel.Empty;
        telemetrySearchModel.fullLevelId = this.state.currentLevelId;
        telemetrySearchModel.playerId = this.state.currentPlayerId;

        if (this.state.applicationMajorVersion !== ""
            && this.state.applicationMinorVersion !== ""
            && this.state.applicationReleaseVersion !== "") {
            telemetrySearchModel.applicationMajorVersion = this.state.applicationMajorVersion;
            telemetrySearchModel.applicationMinorVersion = this.state.applicationMinorVersion;
            telemetrySearchModel.applicationReleaseVersion = this.state.applicationReleaseVersion;
        } else {
            telemetrySearchModel.applicationMajorVersion = null;
            telemetrySearchModel.applicationMinorVersion = null;
            telemetrySearchModel.applicationReleaseVersion = null;
        }
        telemetrySearchModel.applicationVersionExactMatch = this.state.applicationVersionExactMatch

        telemetrySearchModel.playerQuitLevel = this.state.playerQuitLevel;
        telemetrySearchModel.hasPreviouslyCompletedLevel = this.state.hasPreviouslyCompletedLevel;
        telemetrySearchModel.playerLevelRatingMin = this.state.playerLevelRatingMin;
        telemetrySearchModel.playerLevelRatingMax = this.state.playerLevelRatingMax;

        telemetrySearchModel.groupByPlayer = this.state.groupByPlayer;
        telemetrySearchModel.includeAdminEntries = this.state.includeAdminEntries;

       // console.log(telemetrySearchModel);

        return telemetrySearchModel;
    }

    handleApplicationMajorVersionValueChange(event: Event) {
        if (event.target instanceof HTMLInputElement) {
            this.setState({applicationMajorVersion: event.target.value});
        }
    }

    handleApplicationMinorVersionValueChange(event: Event) {
        if (event.target instanceof HTMLInputElement) {
            this.setState({applicationMinorVersion: event.target.value});
        }
    }

    handleApplicationReleaseVersionValueChange(event: Event) {
        if (event.target instanceof HTMLInputElement) {
            this.setState({applicationReleaseVersion: event.target.value});
        }
    }

    handleApplicationVersionIsExactInputChange(event: Event) {
        if (event.target instanceof HTMLInputElement) {
            this.setState({applicationVersionExactMatch: event.target.checked});
        }
    }



    handlePlayerLevelRatingMinValueChange(event: Event) {
        if (event.target instanceof HTMLInputElement) {
            this.setState({playerLevelRatingMin: event.target.value});
        }
    }
    handlePlayerLevelRatingMaxValueChange(event: Event) {
        if (event.target instanceof HTMLInputElement) {
            this.setState({playerLevelRatingMax: event.target.value});
        }
    }

    handlePlayerQuitLevelChange(event: Event) {
        if (event.target instanceof HTMLSelectElement) {
            for (let i = 0; i < event.target.options.length; ++i) {
                let opt = event.target.options[i];
                if (opt.selected) {
                    this.setState({playerQuitLevel: opt.value}, () => {
                    });
                }
            }
        }
    }


    handleHasPreviouslyCompletedLevelChange(event: Event) {
        if (event.target instanceof HTMLSelectElement) {
            for (let i = 0; i < event.target.options.length; ++i) {
                let opt = event.target.options[i];
                if (opt.selected) {
                    this.setState({hasPreviouslyCompletedLevel: opt.value}, () => {
                    });
                }
            }
        }
    }

    handleGroupByPlayerInputChange(event: Event) {
        if (event.target instanceof HTMLInputElement) {
            this.setState({groupByPlayer: event.target.checked});
        }
    }

    handleIncludeAdminEntriesInputChange(event: Event) {
        if (event.target instanceof HTMLInputElement) {
            this.setState({includeAdminEntries: event.target.checked});
        }
    }


    handleShouldShowRawPlayerRetentionValuesInputChange(event: Event) {
        if (event.target instanceof HTMLInputElement) {
            this.setState({shouldShowRawPlayerRetentionValues: event.target.checked});
        }
    }



    fetchPlayerNames() {
       return new Promise((res, rej) => {
           telemetryService.fetchPlayerNames()
           .then((values) => {
               //console.log(values);
               values = values.sort((a,b) => (a.value > b.value) ? 1 : ((b.value > a.value) ? -1 : 0))
               this.setState({playerIdOptions: values});
               res();
           })
           .catch((err) => {
               Utils.notifyException(err, "Error fetching names");
               rej(`Error fetching names: ${err}`);
           });
       });
   }


   handlePlayerIdChange(event: Event) {
       if (event.target instanceof HTMLSelectElement) {
           for (let i = 0; i < event.target.options.length; ++i) {
               let opt = event.target.options[i];
               if (opt.selected) {
                   this.setState({currentPlayerId: opt.value}, () => {
                   });
               }
           }
       }
   }



    fetchLevelIds() {
        return new Promise((res, rej) => {
            telemetryService.fetchLevelIds()
            .then((levelIds) => {
                this.setState({levelIdOptions: levelIds});
                res();
            })
            .catch((err) => {
                Utils.notifyException(err, "Error fetching levels");
                rej(`Error fetching levels: ${err}`);
            });
        });
    }


    handleLevelIdChange(event: Event) {
        if (event.target instanceof HTMLSelectElement) {
            for (let i = 0; i < event.target.options.length; ++i) {
                let opt = event.target.options[i];
                if (opt.selected) {
                    this.setState({currentLevelId: opt.value}, () => {
                    });
                }
            }
        }
    }

    submitSecretFoundRequest(event: Event) {
        telemetryService.fetchSecretFoundTabularData()
        .then((values) => {
            //console.log(values);
            this.setState({secretFoundTabularData: values});
        })
        .catch((err) => {
            Utils.notifyException(err, "Error fetching secret found tabular data");
        });

    }

    submitCutscenePlayedRequest(event: Event) {
        telemetryService.fetchCutscenePlayedTabularData()
            .then((values) => {
                //console.log(values);
                this.setState({cutscenePlayedTabularData: values});
            })
            .catch((err) => {
                Utils.notifyException(err, "Error fetching cutscene played tabular data");
            });

    }



    submitLevelCompletionAnalysisRequest(event: Event) {
        let telemetrySearchModel = this.getSearchModel();
        telemetryService.fetchTelemetryLevelCompletionAnalysisData(telemetrySearchModel)
        .then((values) => {
            this.setState({levelCompletionAnalysisData: values});
        })
        .catch((err) => {
            Utils.notifyException(err, "Error fetching level completion data");
        });
    }



    submitPlayerRetentionAnalysisRequest(event: Event) {
        let telemetrySearchModel = this.getSearchModel();
        telemetryService.fetchTelemetryPlayerRetentionAnalysisData(telemetrySearchModel)
        .then((rawData) => {
           // console.log(JSON.stringify(rawData));
            let totalCount = rawData.reduce((accumulator, currentEntry) => accumulator + parseInt(currentEntry._wasPlayersLastLevelCount, 10), 0);

            let remaining = totalCount;

            let preprocessedData = [];

            rawData.forEach(entry => {
                preprocessedData.push(entry);
            });

            this.state.levelIdOptions.forEach(levelIdEntry => {
                if (!preprocessedData.some(entry => entry._fullLevelId === levelIdEntry)) {
                    if (this.getLevelOrder(levelIdEntry) >= 0) {
                        console.log("Added " + levelIdEntry + ", which was missing");
                        preprocessedData.push({
                            _fullLevelId: levelIdEntry,
                            _wasPlayersLastLevelCount: 0,
                        });
                    }
                }
            });

            let processedData = preprocessedData
            .sort((a, b) => this.getLevelOrder(a._fullLevelId) > this.getLevelOrder(b._fullLevelId) ? 1 : -1)
            .map(record =>  {
                //console.log(record);
                let percentOfRemaining = record._wasPlayersLastLevelCount / remaining;
                let remainingUntilNow = remaining; // So that the current level isn't removed from itself... Otherwise, first level is not 100%, and last leveel is 0%.
                remaining -= record._wasPlayersLastLevelCount;
                return {
                    _fullLevelId: record._fullLevelId,
                    _order: this.getLevelOrder(record._fullLevelId),
                    _wasPlayersLastLevelCount: record._wasPlayersLastLevelCount,
                    _percentageOfTotal: record._wasPlayersLastLevelCount / totalCount,
                    _percentageOfRemaining: percentOfRemaining,
                    _cumulativePercentRemaining: remainingUntilNow / totalCount
                };
            });

            let allPercentages = processedData.map(record => Number(record._percentageOfTotal));
            //console.log(JSON.stringify(allPercentages));
            let maxPercent = Math.max.apply(Math, allPercentages);
            console.log(maxPercent);
            processedData.forEach(record => {
                record._normalizedPercentageOfTotal = record._percentageOfTotal / (maxPercent);
            });

            this.setState({playerRetentionAnalysisData: processedData });
        })
        .catch((err) => {
            Utils.notifyException(err, "Error fetching player retention data");
        });

    }



    getLevelOrder(fullLevelId: string) {
        switch(fullLevelId) {
            case 'Introduction_VRDemo_LikeAGlove_0': return 0;
            case 'Introduction_VRDemo_HighG_0': return 10;
            case 'Introduction_VRDemo_RunTheGauntlet_0': return 20;
            case 'Introduction_Orientation_RealityCheck_0': return 30;
            case 'Introduction_Orientation_SuitYourself_0': return 40;
            case 'BasicAssessment_Platforming_0': return 50;
            case 'BasicAssessment_Acrophobia_0': return 60;
            case 'BasicAssessment_Sidewalk_0': return 70;
            case 'BasicAssessment_FullTransparency_0': return 80;
            case 'BasicAssessment_FogWarning_0': return 90;
            case 'BasicAssessment_OneSmallStep_0': return 100;
            case 'BasicAssessment_BarrierToEntry_0': return 110;
            case 'BasicAssessment_AdvancedPlacement_0': return 115;
            case 'BasicAssessment_FirstGen_0': return 120;
            case 'BasicAssessment_CenterOfGravity_0': return 130;
            case 'BasicAssessment_MovingTarget_0': return 140;
            case 'BasicAssessment_LeapOfFaith_0': return 150;
            case 'BasicAssessment_HardwareUpgrade_0': return 160;
            case 'AdvancedTraining_TerminalVelocity_0': return 170;
            case 'AdvancedTraining_FatalRetraction_0': return 180;
            case 'AdvancedTraining_ClearTheWay_0': return 190;
            case 'AdvancedTraining_VerticalIntegration_0': return 195;
            case 'AdvancedTraining_RightAscension_0': return 200;
            case 'AdvancedTraining_SurfaceTension_0': return 210;
            case 'AdvancedTraining_HighSpeed_0': return 220;
            case 'AdvancedTraining_CrackShot_0': return 230;
            case 'AdvancedTraining_CriticalMass_0': return 240;
            case 'AdvancedTraining_Exothermic_0': return 250;
            case 'AdvancedTraining_HardRain_0': return 260;
            case 'AdvancedTraining_FriendlyFire_0': return 270;
            case 'AdvancedTraining_WallBreaker_0': return 280;
            case 'AdvancedTraining_FullSwing_0': return 290;
            case 'AdvancedTraining_WindowOfOpportunity_0': return 300;
            case 'AdvancedTraining_AirGap_0': return 310;
            case 'AdvancedTraining_Countermeasures_0': return 320;
            case 'AdvancedTraining_MassDestruction_0': return 330;
            case 'AdvancedTraining_GroupEffort_0': return 340;
            case 'AdvancedTraining_TrainingCompleteTemp_0': return 350;
            case 'CombatScenarios_BulletTime_0': return 360;
            case 'CombatScenarios_StoppingPower_0': return 370;
            case 'CombatScenarios_InternalCombustion_0': return 380;
            case 'CombatScenarios_RaceCondition_0': return 390;
            case 'CombatScenarios_ReasonableForce_0': return 400;
            case 'CombatScenarios_CageMaze_0': return 410;
            case 'CombatScenarios_DoubleJeopardy_0': return 420;
            case 'CombatScenarios_Retrograde_0': return 430;
            case 'CombatScenarios_CourseCorrection_0': return 435;
            case 'CombatScenarios_RocketScience_0': return 440;
            case 'CombatScenarios_Misdirection_0': return 450;
            case 'CombatScenarios_SpinOff_0': return 460;
            case 'CombatScenarios_MissileGuidance_0': return 470;
            case 'CombatScenarios_ExitStrategy_0': return 480;
            case 'CoreResearch_PowerLines_0': return 490;
            case 'CoreResearch_OpposingForce_0': return 500;
            case 'CoreResearch_GrowthCurve_0': return 510;
            case 'CoreResearch_DryRun_0': return 520;
            case 'CoreResearch_InverseProblem_0': return 530;
            default: return -1;
        }
    }

}


export default AdminTelemetry;